<template>
    <div class="incubator-proposal">
        <v-form
            ref="form"
            v-model="valid"
        >
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Proposal.title[0]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.proposal.name"
                            outlined
                            :label="$t('IncubatorApply.Proposal.label[0]')"
                            placeholder=""
                            :hint="$t('IncubatorApply.Proposal.hint[0]')"
                            persistent-hint
                            :rules="emptyRule"
                            class="border-orange-input form-input"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Proposal.title[1]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.proposal.property"
                        class="radio-group"
                        :rules="emptyRule"
                    >
                        <v-radio
                            value="臺灣團隊-孵化組"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Proposal.radio[0]') }}
                                </div>
                            </template>
                        </v-radio>
                        <div class="radio-content">
                            <ol>
                                <li>{{ $t('IncubatorApply.Proposal.content[0]') }}</li>
                                <li>{{ $t('IncubatorApply.Proposal.content[1]') }}</li>
                                <li>{{ $t('IncubatorApply.Proposal.content[2]') }}</li>
                            </ol>
                        </div>
                        <v-radio
                            value="臺灣團隊-加速組"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Proposal.radio[1]') }}
                                </div>
                            </template>
                        </v-radio>
                        <div class="radio-content">
                            <ol>
                                <li>{{ $t('IncubatorApply.Proposal.content[3]') }}</li>
                                <li>{{ $t('IncubatorApply.Proposal.content[4]') }}</li>
                                <li>{{ $t('IncubatorApply.Proposal.content[5]') }}</li>
                            </ol>
                        </div>
                        <v-radio
                            value="亞太地區團隊"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Proposal.radio[2]') }}
                                </div>
                            </template>
                        </v-radio>
                        <div class="radio-content">
                            <ol>
                                <li>{{ $t('IncubatorApply.Proposal.content[6]') }}</li>
                                <li>{{ $t('IncubatorApply.Proposal.content[7]') }}</li>
                                <li>{{ $t('IncubatorApply.Proposal.content[8]') }}</li>
                                <li>{{ $t('IncubatorApply.Proposal.content[9]') }}</li>
                            </ol>
                        </div>
                    </v-radio-group>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Proposal.title[2]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-textarea
                        v-model="form.proposal.abstract"
                        rows="10"
                        counter="500"
                        :label="$t('IncubatorApply.Proposal.label[1]')"
                        no-resize
                        :rules="abstractRule"
                        outlined
                        placeholder=""
                        :hint="$t('IncubatorApply.Proposal.hint[1]')"
                        persistent-hint
                    />
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Proposal.title[3]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.proposal.issue"
                        class="radio-group"
                        :rules="emptyRule"
                    >
                        <v-radio
                            value="慈善創新"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Proposal.radio[3]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="醫療照護"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Proposal.radio[4]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="教育創新"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Proposal.radio[5]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="環境保護"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Proposal.radio[6]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="循環經濟"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Proposal.radio[7]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="青銀共創"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Proposal.radio[8]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="食糧供應創新"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Proposal.radio[9]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="災害防救創新"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Proposal.radio[10]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="地方創生/社區營造"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Proposal.radio[11]') }}
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </div>
            </div>
            <div class="apply-form-block team-image-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Proposal.title[4]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="label">
                        {{ $t('IncubatorApply.Proposal.label[2]') }}
                    </div>
                    <v-card
                        elevation="4"
                        class="preview-card rounded-lg"
                    >
                        <v-tooltip
                            top
                            nudge-top="-90"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-img
                                    id="img-file"
                                    height="300px"
                                    :src="$static(form.proposal.img)"
                                    class="og-image"
                                    :contain="loading"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-file-input
                                        v-model="proposalImg"
                                        accept="image/png, image/jpeg"
                                        class="upload pa-0 ma-0"
                                        hide-input
                                        truncate-length="0"
                                        prepend-icon="mdi-upload"
                                        dark
                                        :rules="[imgRule()]"
                                        @change="handleImageUpload"
                                    />
                                </v-img>
                            </template>
                            <span>{{ $t('IncubatorApply.Proposal.image') }}</span>
                        </v-tooltip>
                    </v-card>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Proposal.title[5]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-file-input
                        v-model="tmpPdf"
                        accept=".pdf"
                        :label="$t('IncubatorApply.Proposal.label[3]')"
                        outlined
                        placeholder=""
                        :hint="$t('IncubatorApply.Proposal.hint[2]')"
                        persistent-hint
                        :rules="[pdfRule()]"
                        class="border-orange-input form-input"
                        @change="
                            (file) =>
                                handlePdfChange(`form.proposal.pdfFile`, file, form.proposal.tmpPdf)
                        "
                    />
                    <div
                        v-if="form.proposal.pdfFile"
                        class="preivew"
                    >
                        <a
                            :href="$static(form.proposal.pdfFile)"
                            target="_blank"
                            class="preview-link"
                        >{{ $t('IncubatorApply.Proposal.link') }}</a>
                    </div>
                </div>
            </div>
        </v-form>
        <div class="apply-form-block">
            <div class="left-block" />
            <div class="form-content">
                <div class="actions">
                    <div
                        class="btn white-btn"
                        @click="prevStep"
                    >
                        {{ $t('IncubatorApply.Proposal.button[0]') }}
                    </div>
                    <div
                        class="btn blue-btn"
                        @click="save"
                    >
                        {{ $t('IncubatorApply.Proposal.button[1]') }}
                    </div>
                    <div
                        class="btn orange-btn"
                        @click="nextStep"
                    >
                        {{ $t('IncubatorApply.Proposal.button[2]') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations, mapActions } from 'vuex';
import { compress } from 'jpegasus';

export default {
    components: {},
    data() {
        return {
            valid: false,
            tmpPdf: null,
            loading: false,
            proposalImg: null,
            // rules
            emptyRule: [(v) => !!v || this.$t('IncubatorApply.Proposal.rule[0]')],
            abstractRule: [
                (v) => !!v || this.$t('IncubatorApply.Proposal.rule[0]'),
                (v) => v.length <= 500 || this.$t('IncubatorApply.Proposal.rule[1]'),
            ],
            twelveWordsRule: [
                (v) => !!v || this.$t('IncubatorApply.Proposal.rule[0]'),
                (v) => v.length <= 12 || this.$t('IncubatorApply.Proposal.rule[2]'),
            ],
            subsRule: [(v) => v.length >= 2 || this.$t('IncubatorApply.Proposal.rule[3]')],
            pdfRule() {
                if (this.tmpPdf && this.tmpPdf.type !== 'application/pdf') {
                    return this.$t('IncubatorApply.Proposal.rule[4]');
                }
                if (this.form.proposal.pdfFile) {
                    return true;
                }
                return this.$t('IncubatorApply.Proposal.rule[5]');
            },
            imgRule() {
                if (this.proposalImg || this.form.proposal.img) {
                    return true;
                }
                return this.$t('IncubatorApply.Proposal.rule[6]');
            },
        };
    },
    computed: {
        ...mapState('mIncubatorApply', ['form']),
        applyId() {
            return this.$route.params.id;
        },
    },
    created() {},
    mounted() {},
    methods: {
        ...mapMutations('mNotification', ['handleError', 'setAlert']),
        ...mapActions('mIncubatorApply', ['saveProposal']),
        handlePdfChange(key, file, tmpKey) {
            if (!file) {
                _.set(this, key, '');
            }
            if (file) {
                if (file.size > 1024 * 1024 * 10) {
                    this.setAlert({
                        message: this.$t('alertMessage.data-10mb'),
                        color: 'error',
                    });
                    _.set(this, key, '');
                    _.set(this, tmpKey, '');
                    return;
                }
                _.set(this, key, URL.createObjectURL(file));
            }
        },
        prevStep() {
            this.$router.push({
                name: 'IncubatorTeamData',
                params: {
                    id: this.applyId,
                },
            });
        },
        save() {
            this.saveProposal({
                form: this.form.proposal,
                applyId: this.applyId,
            });
        },
        handleFiles(file) {
            return compress(file, {
                maxHeight: 800,
                maxWidth: 800,
                quality: 1,
                fixImageOrientation: false,
            });
        },
        async onUploadImage(key, file) {
            const compressFile = await this.handleFiles(file); // 可以找其他壓縮方式
            _.set(this, key, URL.createObjectURL(compressFile));
        },
        handleImageUpload(file) {
            if (file) {
                if (file.size > 1024 * 1024 * 2) {
                    this.setAlert({
                        message: this.$t('alertMessage.data-2mb'),
                        color: 'error',
                    });
                    return;
                }
                this.onUploadImage('form.proposal.img', file);
            }
        },
        async nextStep() {
            this.$refs.form.validate();
            if (this.valid) {
                try {
                    await this.saveProposal({
                        form: this.form.proposal,
                        applyId: this.applyId,
                    });
                    this.$router.push({
                        name: 'IncubatorConfirm',
                        params: {
                            id: this.applyId,
                        },
                    });
                } catch {
                    this.setAlert({
                        message: this.$t('alertMessage.data-no-write'),
                        color: 'error',
                    });
                }
            } else {
                this.setAlert({
                    message: this.$t('alertMessage.data-no-write'),
                    color: 'error',
                });
            }
        },
    },
};
</script>

<style lang="scss">
.incubator-proposal {
    .radio-content {
        padding-left: 34px;
        margin-bottom: 10px;
    }

    .team-image-block {
        .preview-card {
            width: 300px;
            height: 300px;
        }

        .label {
            font-weight: 500;
            font-size: 18px;
            line-height: 18px;
            letter-spacing: 0.1em;
            color: $color-gray;
            margin-bottom: 23px;
        }

        .upload {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: rgba(196, 196, 196, 0.4);
            border: 1px solid #ffffff;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            .v-input__prepend-outer {
                margin: 0 !important;
            }
        }
    }
}
#img-file {
    .v-input__control {
        display: block !important;
        position: absolute;
        top: 100%;
        width: 90px;
        text-align: center;
        .v-input__slot::before {
            display: none;
        }
    }
    .v-messages__wrapper{
        display: flex;
        justify-content:center;
    }
    .v-messages__message {
        display: inline;
        text-align: center;
    }
}
</style>
